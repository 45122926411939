<div *ngIf="true">
    <table>
        <thead>
            <tr>
                <th>Vertical</th>
                <th>Provincia</th>
                <th>Descripción</th>
                <th>Solicitudes</th>
                <th>Operaciones</th>
                <th>Tasa Transformación</th>
                <th>Facturación</th>
            </tr>
        </thead>
        <tbody>
            @for (service of data; track service) {
                <tr>
                    <th>{{ service['parentName'] }}</th>
                    <th></th>
                    <th></th>
                    <th>{{ service['requests'] }}</th>
                    <th>{{ service['operations'] }}</th>
                    <th>{{ service['taxes'] }}</th>
                    <th>{{ service['gmv'] }}</th>
                </tr>
                @for ( province of service['province']; track province) {
                    <tr>
                        <td></td>
                        <td [attr.rowspan]="province['offeredServiceChildren'].length">{{ province['name'] }}</td>
                        <td *ngIf="province['offeredServiceChildren'].length > 0">{{ province['offeredServiceChildren'][0]['description'] }}</td>
                        <td *ngIf="province['offeredServiceChildren'].length > 0">{{ province['offeredServiceChildren'][0]['requests'] }}</td>
                        <td *ngIf="province['offeredServiceChildren'].length > 0">{{ province['offeredServiceChildren'][0]['operations'] }}</td>
                        <td *ngIf="province['offeredServiceChildren'].length > 0">{{ province['offeredServiceChildren'][0]['taxes'] }}</td>
                        <td *ngIf="province['offeredServiceChildren'].length > 0">{{ province['offeredServiceChildren'][0]['gmv'] }}</td>
                    </tr>
                    @for ( child of province['offeredServiceChildren']; track child; let index = $index;) {
                        <tr *ngIf="index > 0">
                            <td></td>
                            <td>{{ child['description'] }}</td>
                            <td>{{ child['requests'] }}</td>
                            <td>{{ child['operations'] }}</td>
                            <td>{{ child['taxes'] }}</td>
                            <td>{{ child['gmv'] }}</td>
                        </tr>
                    } @empty {
                        <div>No Prices found...</div>
                    }
                } @empty {
                    <div>No Province found...</div>
                }
            } @empty {
                <div>No Service found...</div>
            }
        </tbody>
        <tfoot>
            <tr>
                <th colspan="3">TOTAL</th>
                <th>18.06%</th>
                <th>2289</th>
                <th>30%</th>
                <th>4820.5</th>
            </tr>
        </tfoot>
    </table>
</div>