import { BILLING_TABLE, GROUP_TABLE, MULTI, SINGLE } from './../fake-data/data';
import { Component, Input, OnInit } from '@angular/core';
import { IBarChartModel, IBrought, IGlobalReport, IPieChartModel, IPriceCompararison, IProfessionalComparison, IProfessionalComparisonVertical, IRatingReport } from '../model/chart-model.model';
import { SubscriptionService } from '../../lm-professionals/services/subscription.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-lm-subscription-reporting',
  templateUrl: './lm-subscription-reporting.component.html',
  styleUrls: ['./lm-subscription-reporting.component.scss']
})
export class LmSubscriptionReportingComponent implements OnInit {

  professionalId: number;

  globalReportData: IGlobalReport;
  broughtReportData: IBrought;
  // pieChartData: IPieChartModel[] = [];
  // horizontalBarChartData: IBarChartModel[] = [];
  // verticalBarChartData: IBarChartModel[] = [];
  // simpleVerticalBarChartData: IPieChartModel[] = [];
  professionalComparison: IProfessionalComparison[] = [];
  professionalComparisonVertical: IProfessionalComparisonVertical[] = [];
  reportPriceComparison: IPriceCompararison;
  reportGlobalRating: IRatingReport;
  chartBarCombo: any[] = [];
  chartLineSeriesCombo: any[];
  chartBarComboVertical: any[] = [];
  chartLineSeriesComboVertical: any[];
  // groupTableData: any[] = [];
  viewChart: [number, number] = [700, 400]


  constructor(
    private route: ActivatedRoute,
    private _subscriptionService: SubscriptionService) { }

  ngOnInit(): void {
    // this.pieChartData = SINGLE;
    // this.horizontalBarChartData = MULTI;
    // this.verticalBarChartData = MULTI;
    // this.simpleVerticalBarChartData = SINGLE;
    // this.groupTableData = GROUP_TABLE;

    this.route.queryParams.subscribe(params => {
      this.professionalId = params['professionalId'];
      this.retriebeGlobalReport();
    });
  }

  retriebeGlobalReport(): void {
    this._subscriptionService.retrieveGlobalReport(this.professionalId).subscribe({
      next: res => {
        this.globalReportData = res.data;
        this.retrieveBroughtReport();
      },
      error: () => {
        console.error('error');
      }
    });
  }

  retrieveBroughtReport(): void {
    this._subscriptionService.retrieveBroughtReport(this.professionalId).subscribe({
      next: res => {
        this.broughtReportData = res.data;
        this.retrieveReportPosition();
        this.retrieveReportPositionVertical();
        this.retrieveReportPriceComparison();
      },
      error: () => {
        console.error('error')
      }
    });
  }

  retrieveReportPosition(): void {
    let body = {
      verticalCode: null
    }
    this._subscriptionService.retrieveReportPosition(this.professionalId, body).subscribe({
      next: res => {
        this.professionalComparison = res.data.professionals;
        this.chartLineSeriesCombo = this.buildChartLineComboPosition();
        this.chartBarCombo = this.buildChartBarComboPosition();
      },
      error: () => {
        console.error('error');
      }
    });
  }

  retrieveReportPositionVertical(): void {
    let body = {
      verticalCode: "Fontaneros"
    }
    this._subscriptionService.retrieveReportPosition(this.professionalId, body).subscribe({
      next: res => {
        this.professionalComparisonVertical = res.data;
        this.chartLineSeriesComboVertical = this.buildChartLineComboPositionVertical();
        this.chartBarComboVertical = this.buildChartBarComboPositionVertical();
      },
      error: () => {
        console.error('error');
      }
    });
  }

  retrieveReportPriceComparison(): void {
    this._subscriptionService.retrieveReportPriceComparison(this.professionalId).subscribe({
      next: res => {
        this.reportPriceComparison = res.data;
        this.retrieveReportRating();
      },
      error: () => {
        console.error('error');
      }
    });
  }

  retrieveReportRating(): void {
    this._subscriptionService.retrieveReportRating(this.professionalId).subscribe({
      next: res => {
        this.reportGlobalRating = res.data;
      },
      error: () => {
        console.error('error');
      }
    });
  }

  buildChartLineComboPosition(): any[] {
    const line: any = {
      name: 'Facturación',
      series: this.professionalComparison.map(item => ({
        name: item.professionalName,
        value: item.transformationalRate
      }))
    };
    return [line];
  }

  buildChartBarComboPosition(): any[] {
    let barChart: any[] = this.professionalComparison.map(item => ({
      name: item.professionalName,
      value: item.billing
    }));
    return barChart;
  }

  buildChartLineComboPositionVertical(): any[] {
    const line = {
      name: 'Facturación',
      series: []
    };

    this.professionalComparisonVertical['verticals'].forEach(vertical => {
      const series = vertical.professionals.map(prof => ({
        name: prof.professionalName,
        value: prof.transformationalRate
      }));
      line.series.push(...series);
    });

    return [line];
  }

  buildChartBarComboPositionVertical(): any[] {
    let barChart: any[]
    this.professionalComparisonVertical['verticals'].forEach(vertical => {
      barChart = vertical.professionals.map(item => ({
        name: item.professionalName,
        value: item.billing
      }));
    });
    return barChart;
  }

}
