import { Component, Input, OnInit } from '@angular/core';
import { IBrought } from '../../model/chart-model.model';

@Component({
  selector: 'app-lm-group-table-basic',
  templateUrl: './lm-group-table-basic.component.html',
  styleUrls: ['./lm-group-table-basic.component.scss']
})
export class LmGroupTableBasicComponent implements OnInit {

  @Input() single: IBrought;
  // data: { parent: any, offeredServiceChildren: any[] }[] = [];
  data: IBrought;
  parents: any[] = [];
  childrens: any[] = [];

  constructor() { }
  ngOnInit(): void {
    this.data = this.single;
    
  }

  // buildTableInformation(): void {
  //   this.data.forEach(item => {
  //     const { offeredServiceChildren, ...parentData } = item;
  //     this.parents.push(parentData);
  //     if (offeredServiceChildren) {
  //       this.childrens.push(...offeredServiceChildren);
  //     }
  //   });
  // }

}
