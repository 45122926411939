import { Component, Input, OnInit } from '@angular/core';
import { IProfessionalComparison, IProfessionalComparisonVertical } from '../../model/chart-model.model';

@Component({
  selector: 'app-lm-comparison-report',
  templateUrl: './lm-comparison-report.component.html',
  styleUrls: ['./lm-comparison-report.component.scss']
})
export class LmComparisonReportComponent implements OnInit {

  @Input() isVertical: boolean;
  @Input() professionalComparison: IProfessionalComparison[];
  @Input() professionalComparisonVertical: IProfessionalComparisonVertical[]
  dataProfessionalComparison: IProfessionalComparison[];
  dataProfessionalComparisonVertical: IProfessionalComparisonVertical[];

  constructor() {

  }
  
  ngOnInit(): void {
    this.dataProfessionalComparison = this.professionalComparison;
    this.dataProfessionalComparisonVertical = this.professionalComparisonVertical;
  }

}
