<div class="content">
    <mat-card class="card">
        <mat-card-content>
            <div class="title_report"><h1>INFORME MENSUAL DE TU ACTIVIDAD EN HOGAMI</h1></div>
            <div class="report">
                <div class="report__section">
                    <p class="report__section__title">Datos Globales</p>
                    <label>El resumen de tu trabajo en este mes es el siguiente:</label>
                </div>
                <div class="report__global" *ngIf="globalReportData">
                    <div class="report__circle">
                        <div>
                            N° Solicitudes<br>
                            {{ globalReportData.numberRequests }}
                        </div>
                    </div>
                    <div class="report__circle">
                        <div>
                            N° Operaciones<br>
                            {{ globalReportData.numberOperations }}
                        </div>
                    </div>
                    <div class="report__circle">
                        <div>
                            Tasa Transformación<br>
                            {{ globalReportData.transformationalRate }} %
                        </div>
                    </div>
                    <div class="report__circle">
                        <div>
                            Transformación<br>
                            {{ globalReportData.billing }} €
                        </div>
                    </div>
                    <div class="report__circle">
                        <div>
                            Rating Global<br>
                            {{ globalReportData.globalRating }}
                        </div>
                    </div>
                </div>
                
                <div class="graphics">
                    <div class="graphics__section">
                        <p class="graphics__section__title">1. QUE ME HA APORTADO Y QUE PODRÍA HABER CONSEGUIDO</p>
                        <label>A continuación tienes la Tasa de Transformación y la Facturación que has obtenido durante este mes.</label>
                    </div>
                    <div class="graphics__report" *ngIf="broughtReportData">
                        <div class="graphics__right">
                            <app-lm-group-table-basic [single]="broughtReportData"></app-lm-group-table-basic>
                        </div>
                    </div>
                </div>
                <div class="graphics">
                    <div class="graphics__report">
                        <div class="graphics__report__one"><p>¿Cuánto has dejado de ganar este mes?</p></div>
                        <div class="graphics__report__two"><label>2531.5</label></div>
                    </div>
                </div>

                <div class="graphics">
                    <div class="graphics__section">
                        <p class="graphics__section__title">2. COMO HA IDO TU MES CON HOGAMI - JUNIO</p>
                        <label>Después de presentar la visión global de tus resultados durante este mes y de lo que podrías haber conseguido, es hora de revisar en detalle cada uno de los indicadores para ver dónde puedes hacer más empeño en mejorar y dónde has obtenido los mejores resultados.</label>
                        <p class="graphics__section__title">Datos generales del Mes</p>
                    </div>
                    <div class="graphics__report">
                        <div class="graphics__right">
                            <!-- <app-lm-group-table [single]="groupTableData"></app-lm-group-table> -->
                        </div>
                    </div>
                </div>
                <div class="graphics_charts">
                    <div class="graphics_charts__right">
                        <!-- <app-lm-chart-pie
                        [single]="pieChartData"
                        [view]="viewChart"></app-lm-chart-pie> -->
                    </div>
                    <div class="graphics_charts__left">
                        <!-- <app-lm-chart-horizontal-bar 
                        [single]="horizontalBarChartData"
                        [view]="viewChart"></app-lm-chart-horizontal-bar> -->
                    </div>
                </div>
                <div class="graphics">
                    <div class="graphics__section">
                        <p class="graphics__section__title">DATOS POR</p>
                        <p class="graphics__section__title">HOGAMI DIRECT</p>
                    </div>
                    <div class="graphics__report">
                        <div class="graphics__right">
                            <!-- <app-lm-group-table [single]="groupTableData"></app-lm-group-table> -->
                        </div>
                    </div>
                </div>
                <div class="graphics_charts">
                    <div class="graphics_charts__left">
                        <!-- <app-lm-chart-pie
                        [single]="pieChartData"
                        [view]="viewChart"></app-lm-chart-pie> -->
                    </div>
                    <div class="graphics_charts__right">
                        <!-- <app-lm-chart-horizontal-bar 
                        [single]="horizontalBarChartData"
                        [view]="viewChart"></app-lm-chart-horizontal-bar> -->
                    </div>
                </div>

                <div class="graphics">
                    <div class="graphics__section">
                        <p class="graphics__section__title">3. MI POSICIONAMIENTO COMO PROFESIONAL EN HOGAMI</p>
                        <label>Hasta ahora hemos visto cómo ha ido el mes en detalle, conoces de dónde recibes más solicitudes, si desde Hogami Direct o desde la Web; conoces qué servicios son los que mejor transformas y en cuáles tienes que poner más foco para mejorar.</label>
                        <p class="graphics__section__title">A Nivel General</p>
                    </div>
                    <div class="graphics__report">
                        <div class="graphics__left" *ngIf="chartLineSeriesCombo && chartBarCombo">
                            <app-lm-chart-combo
                            [barChart]="chartBarCombo"
                            [lineChartSeries]="chartLineSeriesCombo"
                            [view]="viewChart"></app-lm-chart-combo>
                        </div>
                    </div>
                    <div class="graphics__report" *ngIf="professionalComparison.length > 0">
                        <div class="graphics__left">
                            <app-lm-comparison-report 
                            [professionalComparison]="professionalComparison"
                            [professionalComparisonVertical]="null"
                            [isVertical]="false"></app-lm-comparison-report>
                        </div>
                    </div>
                </div>

                <div class="graphics">
                    <div class="graphics__section">
                        <p class="graphics__section__title">A Nivel Vertical</p>
                    </div>
                    <div class="graphics__report">
                        <div class="graphics__left">
                            <app-lm-chart-combo *ngIf="chartLineSeriesComboVertical && chartBarComboVertical"
                            [barChart]="chartBarComboVertical"
                            [lineChartSeries]="chartLineSeriesComboVertical"
                            [view]="viewChart"></app-lm-chart-combo>
                        </div>
                    </div>
                    <div class="graphics__report" *ngIf="professionalComparisonVertical['verticals']">
                        <div class="graphics__left">
                            <app-lm-comparison-report 
                            [professionalComparison]="null"
                            [professionalComparisonVertical]="professionalComparisonVertical"
                            [isVertical]="true"></app-lm-comparison-report>
                        </div>
                    </div>
                </div>


                <div class="graphics">
                    <div class="graphics__section">
                        <p class="graphics__section__title">COMPARATIVA DEL PRECIO DE INSTALACIÓN CON EL PROMEDIO POR PROVINCIA</p>
                        <label>Te incluimos la información relativa al tu precio de instalación (IVA incluido) en los Servicios comparado con el precio más repetido en esos servicios del total de profesionales en cada provincia y el precio mínimo en el servicio, de esta manera puedes confirmar si tu precio es competitivo frente al resto de profesionales.</label>
                    </div>
                    <div class="graphics__report">
                        <div class="graphics__left">
                            <app-lm-group-table-comparition *ngIf="reportPriceComparison != null"
                            [groupTablePriceComparative]="reportPriceComparison"></app-lm-group-table-comparition>
                        </div>
                    </div>
                </div>

                <div class="graphics">
                    <div class="graphics__section">
                        <p class="graphics__section__title">4. DATOS ACUMULADOS 2024 - ¿QUÉ ME HA APORTADO HOGAMI ESTE AÑO?</p>
                        <p class="graphics__section__title">Desde Enero 2025</p>
                        <label>¿Qué has recibido y qué has hecho desde enero de 2024  en Hogami?</label>
                        <label>A continuación tienes la información de las solicitudes recibidas, las operaciones cerradas, la Tasa de Transformación y la Facturación que has obtenido gracias a Hogami. Esta información está a nivel de Vertical y de Servicio, teniendo en cuenta también las provincias en las que actualmente prestas servicio.</label>
                    </div>
                    <div class="graphics_charts__left">
                        <!-- <app-lm-chart-vertical-bar 
                        [single]="horizontalBarChartData"
                        [view]="viewChart"></app-lm-chart-vertical-bar> -->
                    </div>
                </div>

                <div class="graphics">
                    <div class="graphics__section">
                        <label>Y, por útlimo, es muy importante conocer el Rating que tienes a nivel global acumulado junto con el que tienes por Vertical y por Servicio.</label>
                    </div>
                    <div class="graphics__report" *ngIf="reportGlobalRating">
                        <div class="graphics__report__one"><p>RATING GLOBAL HOGAMI</p></div>
                        <div class="graphics__report__two"><label>{{ reportGlobalRating.ratingHogamiGlobal }}</label></div>
                    </div>
                    <div class="graphics__left" *ngIf="reportGlobalRating">
                        <app-lm-global-table [reportGlobalRating]="reportGlobalRating"></app-lm-global-table>
                    </div>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>