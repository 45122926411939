import { Component, Input } from '@angular/core';
import { SINGLE } from '../../fake-data/data';
import { IPieChartModel } from '../../model/chart-model.model';

@Component({
  selector: 'app-lm-chart-pie',
  templateUrl: './lm-chart-pie.component.html',
  styleUrls: ['./lm-chart-pie.component.scss']
})
export class LmChartPieComponent {

  @Input() single: IPieChartModel[] = [];
  @Input() view: [number, number];
  data: IPieChartModel[];
  
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = false;
  isDoughnut: boolean = false;
  legendPosition: any = 'right';

  colorScheme: any = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  constructor() {
    this.data = this.single;
  }

  onSelect(data: any): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data: any): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data: any): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

}
