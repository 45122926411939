import { Component, Input, OnInit } from '@angular/core';
import { IPriceCompararison } from '../../model/chart-model.model';

@Component({
  selector: 'app-lm-group-table-comparition',
  templateUrl: './lm-group-table-comparition.component.html',
  styleUrls: ['./lm-group-table-comparition.component.scss']
})
export class LmGroupTableComparitionComponent implements OnInit {

  @Input() groupTablePriceComparative: IPriceCompararison;
  data: any;

  constructor() { }

  ngOnInit(): void {
    this.data = this.groupTablePriceComparative;
  }

}
