<div>
    <table>
        <thead>
            <tr>
                <th>Vertical</th>
                <th>Servicio</th>
                <th>Rating</th>
            </tr>
        </thead>
        <tbody>
            @for (vertical of reportGlobalRating.verticals; track vertical) {
                <tr>
                    <td [attr.colspan]="2">{{ vertical.verticalName }}</td>
                    <td class="toright">{{ vertical.rating }}</td>
                </tr>
                @for ( service of vertical.services; track service) {
                    <tr>
                        <td></td>
                        <td>{{ service.serviceName }}</td>
                        <td class="toright">{{ service.rating }}</td>
                    </tr>
                }
            }
            <tr>
                <th [attr.colspan]="2">Rating Global</th>
                <th class="toright"> {{reportGlobalRating.ratingHogamiGlobal}} </th>
            </tr>
        </tbody>
    </table>
</div>