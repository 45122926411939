import { ISubscriptionHistory } from './../../models/lm-professional.model';
import { Component, Input } from '@angular/core';
import { IFiltersData, ISubscriptionInformation, ProfessionalBasicData } from '../../models/lm-professional.model';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ProfessionalService } from '../../services/professional.service';
import { SubscriptionService } from '../../services/subscription.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SubscriptionModalComponent } from 'src/app/pages/modals/subscription-dialog/subscription-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-lm-professional-subscription',
  templateUrl: './lm-professional-subscription.component.html',
  styleUrls: ['./lm-professional-subscription.component.scss', '../../../../pages/utils/mat-table.scss', '../../../../pages/utils/form-search.scss']
})
export class LmProfessionalSubscriptionComponent {

  dialogRef: MatDialogRef<SubscriptionModalComponent>;
  _translateSubscription: Subscription;
  titleForm: string;
  @Input() filtersData: IFiltersData;
  professionalInfo: ProfessionalBasicData;
  subscriptionInformation: ISubscriptionInformation;
  subscriptionHistory: ISubscriptionHistory[] = [];
  displayedColumns: string[] = ['subscriptionPlan', 'action', 'startDate', 'endDate', 'proccesed', 'comment'];
  subscriptionCode: string;
  page: number = 0;
  totalElements: number = 0;
  pageSize: number = 10;

  constructor(
    private _translateService: TranslateService,
    private _professionalService: ProfessionalService,
    private _subscriptionService: SubscriptionService,
    public _dialog: MatDialog,
    private _router: Router) { }

  ngOnInit() {
    this.professionalInfo = this.filtersData.professionalObject;
    this._translateSubscription = this._translateService.get('lm-professional.lm-professional-subscription').subscribe(res => {
      this.titleForm = res['title'];
    });
    if (this.professionalInfo.professionalSubscription) {
      this.subscriptionCode = this.professionalInfo.professionalSubscription.subscriptionVersion.subscription.code;
      this.retrieveProfessionalSubcriptionInformation();
      this.retrieveProfessionalSubscriptionHistory();
    }
  }

  /**
   * Retrieve Professional Subscription Information
   */
  retrieveProfessionalSubcriptionInformation(): void {
    this._subscriptionService.retrieveProfessionalSubcriptionInformation(this.subscriptionCode)
      .subscribe(res => {
        this.subscriptionInformation = res.data;
      });
  }

  /**
   * Retrieve Professional Subscriptions
   */
  retrieveProfessionalSubscriptionHistory(): void {
    this._professionalService.retrieveProfessionalSubscriptionHistory(this.filtersData.professionalId, this.page, this.pageSize)
      .subscribe({
        next: (res) => {
          this.subscriptionHistory = res.data?.content;
          this.totalElements = res.data.totalElements
        },
        error: () => {
          this.subscriptionHistory = [];
        }
      });
  }

  getPaginatorData(event) {
    this.page = event['pageIndex'];
    this.pageSize = event['pageSize'];
    this.retrieveProfessionalSubscriptionHistory();
  }

  openDialogSubscription(type?: string) {
    if (type === undefined) {
      type = 'EDIT';
    }
    this.dialogRef = this._dialog.open(SubscriptionModalComponent, {
      width: '600px',
      height: 'auto',
      disableClose: false,
      data: {
        value: {
          'type': type,
          'subscriptionCode': this.subscriptionCode,
          'professionalId': this.filtersData.professionalId,
          'subscriptionStatusCode': this.professionalInfo?.professionalSubscription?.status?.code,
          'subscription': this.professionalInfo?.professionalSubscription?.subscriptionVersion?.subscription
        }
      }
    });

    this.dialogRef.afterClosed().subscribe(result => {
      console.debug('The dialog was closed');
    });

    this.dialogRef.componentInstance.submitClicked.subscribe(result => {
      if (result === 'submit') {
        window.location.reload();
      } else {
        this.dialogRef.close();
      }
    });
  }

  submitReport(): void {
    this.openNewWindow(this.filtersData.professionalId);
  }

  private openNewWindow(professionalId: any): void {
    this._router.navigate(['/reporting'], { queryParams: { professionalId: professionalId } });
  }

}
