import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Color, LegendPosition, ScaleType } from '@swimlane/ngx-charts';
import { BARCHART, LINECHARTSERIES } from '../../fake-data/data';

@Component({
  selector: 'app-lm-chart-combo',
  templateUrl: './lm-chart-combo.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./lm-chart-combo.component.scss']
})
export class LmChartComboComponent implements OnInit {

  // @Input() single: IPieChartModel[] = [];
  @Input() view: [number, number];
  @Input() barChart: any[];
  @Input() lineChartSeries: any[];

  // options
  showXAxis = true;
  showYAxis = true;
  animations: boolean = true;
  tooltipDisabled: boolean = false;
  gradient = false;
  showLegend = true;
  legendTitle = 'Legenda';
  legendPosition = LegendPosition.Right;
  showXAxisLabel = true;
  showGridLines = true;
  showYAxisLabel = false;
  xAxisLabel = '';
  yAxisLabel = 'Tasa Transformación';
  yAxisLabelRight: string = '';
  noBarWhenZero: boolean = true;
  wrapTicks = false;

  // barChart: any[] = BARCHART;
  // lineChartSeries: any[] = LINECHARTSERIES;

  comboBarScheme: Color = {
    name: 'singleLightBlue',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#01579b']
  };

  lineChartScheme: Color = {
    name: 'coolthree',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#01579b', '#7aa3e5', '#a8385d', '#00bfa5']
  };

  constructor() {

  }

  ngOnInit(): void {
  }

  yLeftAxisScale(min, max) {
    return { min: `${min}`, max: `${max}` };
  }

  yRightAxisScale(min, max) {
    return { min: `${min}`, max: `${max}` };
  }

  yLeftTickFormat(data) {
    return `${data.toLocaleString()}`;
  }

  onSelect(event) {
    console.log(event);
  }

  yRightTickFormat(data) {
    return `${data}%`;
  }


}
