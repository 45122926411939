<ngx-charts-pie-chart
    [view]="view"
    [scheme]="colorScheme"
    [results]="single"
    [gradient]="gradient"
    [legend]="showLegend"
    [legendTitle]="'Solicitudes'"
    [legendPosition]="legendPosition"
    [labels]="showLabels"
    [doughnut]="true"
    [doughnut]="isDoughnut"
    (select)="onSelect($event)"
    (activate)="onActivate($event)"
    (deactivate)="onDeactivate($event)"
    >
</ngx-charts-pie-chart>