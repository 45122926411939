<div>
    <table>
        <thead>
            <tr>
                <th>Provincia</th>
                <th>Servicio</th>
                <th>Tu Precio 1° Unidad</th>
                <th>Precio en Hogami más repetido  1ª unidad</th>
                <th>Precio Mínimo en 1ª Unidad</th>
                <th>Tu Precio 2ª unidad</th>
                <th>Precio en Hogami más repetido  2ª unidad</th>
                <th>Precio Mínimo en 2ª Unidad</th>
            </tr>
        </thead>
        <tbody>
            @for (province of data['provinces']; track province) {
                <tr>
                    <td [attr.rowspan]="province['services'].length +1">{{ province['provinceName'] }}</td>
                </tr>
                @for ( service of province['services']; track service) {
                    <tr>
                        <td>{{ service['serviceName'] }}</td>
                        <td class="toright">{{ service['priceFirstUnit'] }}</td>
                        <td class="toright">{{ service['priceMostRepeatedFirstUnit'] }}</td>
                        <td class="toright">{{ service['priceMinimumFirstUnit'] }}</td>
                        <td class="toright">{{ service['priceSecondUnit'] }}</td>
                        <td class="toright">{{ service['priceMostRepeatedSecondUnit'] }}</td>
                        <td class="toright">{{ service['priceMinimumSecondUnit'] }}</td>
                    </tr>
                }
            }
        </tbody>
    </table>
</div>