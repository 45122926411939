import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  private BASE_URL = environment.apiHosts;

  constructor(private _http: HttpClient) { }

  retrieveProfessionalSubcriptionInformation(subscriptionCode: string): Observable<any> {
    const head = new HttpHeaders({ 'Accept-Language': 'es-ES' });
    return this._http.get(`${this.BASE_URL}/backoffice-server/api/v1/professionals/subscriptions/${subscriptionCode}`,
      { headers: head });
  }

  retrieveProfessionalSubcriptionAllowed(subscriptionCode: string): Observable<any> {
    const head = new HttpHeaders({ 'Accept-Language': 'es-ES' });
    return this._http.get(`${this.BASE_URL}/backoffice-server/api/v1/professionals/subscriptions/${subscriptionCode}/allowed`,
      { headers: head });
  }

  retrieveSubscriptions(): Observable<any> {
    const head = new HttpHeaders({ 'Accept-Language': 'es-ES' });
    return this._http.get(`${this.BASE_URL}/backoffice-server/api/v1/professionals/subscriptions`,
      { headers: head });
  }

  retrieveProfessionalSubscriptionStatus(): Observable<any> {
    const head = new HttpHeaders({ 'Accept-Language': 'es-ES' });
    return this._http.get(`${this.BASE_URL}/backoffice-server/api/v1/professionals/subscriptions/status`,
      { headers: head });
  }

  retrieveGlobalReport(professionalId: number): Observable<any> {
    const head = new HttpHeaders({ 'Accept-Language': 'es-ES' });
    return this._http.get(`${this.BASE_URL}/backoffice-server/api/v1/professionals/${professionalId}/subscription/report/global`,
      { headers: head });
  }

  retrieveBroughtReport(professionalId: number): Observable<any> {
    const head = new HttpHeaders({ 'Accept-Language': 'es-ES' });
    return this._http.get(`${this.BASE_URL}/backoffice-server/api/v1/professionals/${professionalId}/subscription/report/brought`,
      { headers: head });
  }

  retrieveReportPosition(professionalId: number, body: any): Observable<any> {
    const head = new HttpHeaders({ 'Accept-Language': 'es-ES' });
    return this._http.post(`${this.BASE_URL}/backoffice-server/api/v1/professionals/${professionalId}/subscription/report/position`,
      body, { headers: head });
  }

  retrieveReportPriceComparison(professionalId: number): Observable<any> {
    const head = new HttpHeaders({ 'Accept-Language': 'es-ES' });
    return this._http.get(`${this.BASE_URL}/backoffice-server/api/v1/professionals/${professionalId}/subscription/report/comparison`,
      { headers: head });
  }

  retrieveReportRating(professionalId: number): Observable<any> {
    const head = new HttpHeaders({ 'Accept-Language': 'es-ES' });
    return this._http.get(`${this.BASE_URL}/backoffice-server/api/v1/professionals/${professionalId}/subscription/report/ratings`,
      { headers: head });
  }

}
