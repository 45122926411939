import { Component, Input, OnInit } from '@angular/core';
import { IRatingReport } from '../../model/chart-model.model';

@Component({
  selector: 'app-lm-global-table',
  templateUrl: './lm-global-table.component.html',
  styleUrls: ['./lm-global-table.component.scss']
})
export class LmGlobalTableComponent implements OnInit {

  @Input() reportGlobalRating: IRatingReport;

  constructor() { }

  ngOnInit(): void {
  }
  

}
