import { Component, Input } from '@angular/core';
import { IBarChartModel } from '../../model/chart-model.model';

@Component({
  selector: 'app-lm-chart-vertical-bar',
  templateUrl: './lm-chart-vertical-bar.component.html',
  styleUrls: ['./lm-chart-vertical-bar.component.scss']
})
export class LmChartVerticalBarComponent {

  @Input() single: IBarChartModel[] = [];
  @Input() view: [number, number];
  data: IBarChartModel[];

  gradient: boolean = true;
  showLegend: boolean = true;
  showXAxis = true;
  showYAxis = true;
  showXAxisLabel = true;
  xAxisLabel = 'Solicitudes por servicios';
  showYAxisLabel = true;
  yAxisLabel = 'Cantidad';

  colorScheme: any = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA']
  };

  constructor() {
    this.data = this.single;
  }

  onSelect(data: any): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data: any): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data: any): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

}
