export interface IPieChartModel {
  name: string;
  value: number;
}

export interface IBarChartModel {
  name: string;
  series: Series[];
}
export interface Series {
  name: string;
  value: number;
}

export interface IGlobalReport {
  professionalId: number
  numberRequests: number
  numberOperations: number
  transformationalRate: number
  billing: number
  globalRating: number
}

export interface IBrought {
  professionalId: number
  verticals: IVertical[]
  transformationRate: number
  billing: number
  transformationRatePossible: number
  billingPossible: number
  lostProfit: number
}

export interface IVertical {
  verticalName: string
  services: IService[]
  transformationRate: number
  billing: number
  transformationRatePossible: number
  billingPossible: number
}

export interface IService {
  serviceName: string
  numberRequest: number
  numberOperation: number
  transformationRate: number
  billing: number
  transformationRatePossible: number
  billingPossible: number
}

export interface IProfessionalComparison {
  type: string
  professionalName: string
  transformationalRate: number
  billing: number
  rating: number
}

export interface IProfessionalComparisonVertical {
  verticals: IVerticalComparison[]
}

export interface IVerticalComparison {
  verticalName: string
  professionals: IProfessionalComparison[]
}

export interface IPriceCompararison {
  professionalId: number
  provinces: IProvince[]
}

export interface IProvince {
  provinceName: string
  services: IService[]
}

export interface IService {
  serviceName: string
  numberRequest: number
  numberOperation: number
  transformationRate: number
  billing: number
  priceFirstUnit: number
  priceMostRepeatedFirstUnit: number
  priceMinimumFirstUnit: number
  priceSecondUnit?: number
  priceMostRepeatedSecondUnit?: number
  priceMinimumSecondUnit?: number
  isGreaterFirstPrice: boolean
}

export interface IRatingReport {
  professionalId: number
  ratingProfessionalGlobal: number
  verticals: IVerticalRatingReport[]
  ratingHogamiGlobal: number
}

export interface IVerticalRatingReport {
  verticalName: string
  services: IServiceRatingReport[]
  rating: number
}

export interface IServiceRatingReport {
  serviceName: string
  numberRequest: number
  numberOperation: number
  transformationRate: number
  billing: number
  rating: number
}
