import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-lm-group-table',
  templateUrl: './lm-group-table.component.html',
  styleUrls: ['./lm-group-table.component.scss']
})
export class LmGroupTableComponent implements OnInit {

  @Input() single: any[] = [];
  data: any[];

  constructor() {
  }

  ngOnInit(): void {
    this.data = this.single;
  }
}
