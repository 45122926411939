<div *ngIf="!isVertical">
    <table>
        <tr>
            <th>Profesional</th>
            <th>Tasa Transformación</th>
            <th>Facturación</th>
            <th>Rating</th>
        </tr>
        <tbody *ngIf="dataProfessionalComparison">
                @for (item of dataProfessionalComparison; track item) {
                    <tr>
                        @if (item.type == 'PROFESSIONAL_TOP') {
                            <td class="toright">{{item.professionalName}}</td>
                            <td class="toright">{{item.transformationalRate}} %</td>
                            <td class="toright">{{item.billing}}</td>
                            <td class="toright">{{item.rating}}</td>
                        }
                    </tr>
                    <tr>
                        @if (item.type == 'PROFESSIONAL') {
                            <td class="professional">{{item.professionalName}}</td>
                            <td class="professional">{{item.transformationalRate}} %</td>
                            <td class="professional">{{item.billing}}</td>
                            <td class="professional">{{item.rating}}</td>
                        }
                    </tr>
                }
                @for (item of dataProfessionalComparison; track item) {
                    @if (item.type == 'PROFESSIONAL_AVG') {
                        <tr>
                            <td class="toright">{{item.professionalName}}</td>
                            <td class="toright">{{item.transformationalRate}} %</td>
                            <td class="toright">{{item.billing}}</td>
                            <td class="toright">{{item.rating}}</td>
                        </tr>
                    }
                }
        </tbody>
    </table>
</div>

<div *ngIf="isVertical">
    <table>
        <tr>
            <th>{{dataProfessionalComparisonVertical['verticals'][0]['verticalName']}}</th>
        </tr>
        <tr>
            <th>Profesional</th>
            <th>Tasa Transformación</th>
            <th>Facturación</th>
            <th>Rating</th>
        </tr>
        <tbody *ngIf="dataProfessionalComparisonVertical">
            @for (item of dataProfessionalComparisonVertical['verticals'][0]['professionals']; track item) {
                <tr>
                    @if (item.type == 'PROFESSIONAL_TOP') {
                        <td class="toright">{{item.professionalName}}</td>
                        <td class="toright">{{item.transformationalRate}} %</td>
                        <td class="toright">{{item.billing}}</td>
                        <td class="toright">{{item.rating}}</td>
                    }
                </tr>
                <tr>
                    @if (item.type == 'PROFESSIONAL') {
                        <td class="professional">{{item.professionalName}}</td>
                        <td class="professional">{{item.transformationalRate}} %</td>
                        <td class="professional">{{item.billing}}</td>
                        <td class="professional">{{item.rating}}</td>
                    }
                </tr>
            }
            @for (item of dataProfessionalComparisonVertical['verticals'][0]['professionals']; track item) {
                @if (item.type == 'PROFESSIONAL_AVG') {
                    <tr>
                        <td class="toright">{{item.professionalName}}</td>
                        <td class="toright">{{item.transformationalRate}} %</td>
                        <td class="toright">{{item.billing}}</td>
                        <td class="toright">{{item.rating}}</td>
                    </tr>
                }
            }
        </tbody>
    </table>
</div>