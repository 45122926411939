<div class="content">
    <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
            <mat-panel-title>
              {{ titleForm }}
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="scontainer">
            <div class="scontainer__left">
                <div class="scontainer__left__column">
                    <div class="scontainer__item_left" *ngIf="professionalInfo?.professionalSubscription">
                       <div class="scontainer__form">
                           <strong>{{ 'lm-professional.lm-professional-subscription.form.f_subscription' | translate }}</strong>
                           <label class="scontainer__label">{{ professionalInfo?.professionalSubscription?.subscriptionVersion?.subscription?.subscriptionDescriptions[0].description }}</label>
                       </div>
                       <div class="scontainer__form">
                           <strong>{{ 'lm-professional.lm-professional-subscription.form.f_validity' | translate }}</strong>
                           <label class="scontainer__label">{{ professionalInfo?.professionalSubscription?.startDate | date: 'dd/MM/yyyy' }} - {{ professionalInfo?.professionalSubscription?.endDate | date: 'dd/MM/yyyy' }}</label>
                       </div>
                       <div class="scontainer__form">
                           <strong>{{ 'lm-professional.lm-professional-subscription.form.f_status' | translate }}</strong>
                           <label class="scontainer__label">{{ professionalInfo?.professionalSubscription?.status?.professionalSubscriptionStatusDescriptions[0].description }}</label>
                       </div>
                       <div class="scontainer__form">
                           <strong>{{ 'lm-professional.lm-professional-subscription.form.f_cat_covered' | translate }}</strong>
                           <label class="scontainer__label">{{ professionalInfo?.categoryNumberCovered }}</label>
                       </div>
                   </div>
                   <div class="scontainer__itemb" *ngIf="subscriptionInformation">
                       <strong>{{ 'lm-professional.lm-professional-subscription.form.f_benefits' | translate }}</strong>
                       <div class="scontainer__itemb__benefits" *ngIf="subscriptionInformation.features.length > 0">
                           <label class="scontainer__label" *ngFor="let item of subscriptionInformation.features">{{ item.featureDescriptions[0].description}}</label>
                       </div>
                   </div>
                </div>
                <div *ngIf="professionalInfo?.professionalSubscription; else createSubscription">
                    <button mat-raised-button color="primary" class="button_submit" (click)="openDialogSubscription()">
                        <mat-icon>edit</mat-icon>{{ 'lm-professional.lm-professional-subscription.form.f_button_change' | translate }}
                    </button>
                </div>
                <ng-template #createSubscription>
                    <div>
                        <button mat-raised-button color="primary" class="button_submit" (click)="openDialogSubscription('CREATE')">
                            <mat-icon>save</mat-icon>{{ 'lm-professional.lm-professional-subscription.form.f_create_subs' | translate }}
                        </button>
                    </div>
                </ng-template>
            </div>
            <div class="scontainer__right" *ngIf="subscriptionInformation">
                <div class="scontainer__item_right">
                    <div class="scontainer__form">
                        <strong>Reportes de suscripciones</strong>
                    </div>
                    <div class="scontainer__form">
                        <button mat-raised-button color="primary" type="submit" class="button_submit" (click)="submitReport()">
                            <mat-icon>area_chart</mat-icon>Ver
                        </button>
                    </div>
                </div>
            </div>
       </div>
    </mat-expansion-panel>
</div>

<div class="content">
    <div class="example-container" *ngIf="subscriptionHistory.length > 0">
        <mat-table mat-table [dataSource]='subscriptionHistory' class="mat-elevation-z8">
            <ng-container matColumnDef="subscriptionPlan" class="spacing">
                <mat-header-cell *matHeaderCellDef>{{ 'lm-professional.lm-professional-subscription.table.subscriptionPlan' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.subscriptionVersion.subscription.subscriptionDescriptions[0].description }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="action" class="spacing">
                <mat-header-cell *matHeaderCellDef>{{ 'lm-professional.lm-professional-subscription.table.action' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.professionalSubscriptionStatus.professionalSubscriptionStatusDescriptions[0].description }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="startDate" class="spacing">
                <mat-header-cell *matHeaderCellDef>{{ 'lm-professional.lm-professional-subscription.table.startDate' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.statusStartDate | date: 'dd/MM/yyyy' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="endDate" class="spacing">
                <mat-header-cell *matHeaderCellDef>{{ 'lm-professional.lm-professional-subscription.table.endData' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.statusEndDate | date: 'dd/MM/yyyy' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="proccesed" class="spacing">
                <mat-header-cell *matHeaderCellDef>{{ 'lm-professional.lm-professional-subscription.table.proccesed' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.subscriptionVersion.startDate | date: 'dd/MM/yyyy' }}
                </mat-cell>
            </ng-container>
            <ng-container matColumnDef="comment" class="spacing">
                <mat-header-cell *matHeaderCellDef>{{ 'lm-professional.lm-professional-subscription.table.comment' | translate }}</mat-header-cell>
                <mat-cell *matCellDef="let element">
                    {{ element.comment }}
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <mat-paginator [length]="totalElements" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]" (page)="getPaginatorData($event)">

        </mat-paginator>
    </div>
</div>