<div>
    <table>
        <thead>
            <tr>
                <td class="border_zero"></td>
                <td class="border_zero"></td>
                <th colspan="2">Facturación Actual</th>
                <th colspan="2">Facturación Posible</th>
            </tr>
            <tr>
                <th>Servicio</th>
                <th>Descripción</th>
                <th>Tasa Transformación</th>
                <th>Facturación</th>
                <th>Tasa Transformación</th>
                <th>GMV</th>
            </tr>
        </thead>
        <tbody  *ngIf="data.verticals">
            @for (vertical of data.verticals; track vertical) {
                <tr>
                    <td class="border_up" colspan="2">{{ vertical.verticalName }}</td>
                    <td class="toright">{{ vertical.transformationRate }} %</td>
                    <td class="toright">{{ vertical.billing }}</td>
                    <td class="toright">{{ vertical.transformationRatePossible }} %</td>
                    <td class="toright">{{ vertical.billingPossible }}</td>
                </tr>
                @for (service of vertical.services; track service) {
                    <tr>
                        <td></td>
                        <td>{{ service.serviceName }}</td>
                        <td class="toright">{{ service.transformationRate }}</td>
                        <td class="toright">{{ service.billing }}</td>
                        <td class="toright">{{ service.transformationRatePossible }}</td>
                        <td class="toright">{{ service.billingPossible }}</td>
                    </tr>
                } @empty { 
                    <div>No Childs found...</div>
                }
            } @empty {
                <div>No Parents found...</div>
            }
            
        </tbody>
        <tfoot>
            <tr>
                <th colspan="2">TOTAL</th>
                <th class="toright">{{ data.transformationRate }} %</th>
                <th class="toright">{{ data.billing }}</th>
                <th class="toright">{{ data.transformationRatePossible }} %</th>
                <th class="toright">{{ data.billingPossible }}</th>
            </tr>
        </tfoot>
    </table>
</div>