import { Component, Input, Output, EventEmitter, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';
import { BarOrientation, formatLabel, PlacementTypes, StyleTypes } from '@swimlane/ngx-charts';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'g[ngx-combo-charts-series-vertical]',
  template: `
    <svg:g
      ngx-charts-bar
      *ngFor="let bar of bars; trackBy: trackBy"
      [@animationState]="'active'"
      [width]="bar.width"
      [height]="bar.height"
      [x]="bar.x"
      [y]="bar.y"
      [fill]="bar.color"
      [stops]="bar.gradientStops"
      [data]="bar.data"
      [orientation]="barOrientation.Vertical"
      [roundEdges]="bar.roundEdges"
      [gradient]="gradient"
      [isActive]="isActive(bar.data)"
      [animations]="animations"
      [noBarWhenZero]="noBarWhenZero"
      (select)="onClick($event)"
      (activate)="activate.emit($event)"
      (deactivate)="deactivate.emit($event)"
      ngx-tooltip
      [tooltipDisabled]="tooltipDisabled"
      [tooltipPlacement]="placementTypes.Top"
      [tooltipType]="styleTypes.tooltip"
      [tooltipTitle]="bar.tooltipText"
    ></svg:g>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('animationState', [
      transition('* => void', [
        style({
          opacity: 1,
          transform: '*'
        }),
        animate(500, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class ComboSeriesVerticalComponent implements OnChanges {
  @Input() dims;
  @Input() type = 'standard';
  @Input() series: any[] = []; 
  @Input() seriesLine: any[] = [];
  @Input() xScale;
  @Input() yScale;
  @Input() colors;
  @Input() tooltipDisabled: boolean = false;
  @Input() gradient: boolean;
  @Input() activeEntries: any[];
  @Input() seriesName: string;
  @Input() animations: boolean = true;
  @Input() noBarWhenZero: boolean = true;

  @Output() select = new EventEmitter();
  @Output() activate = new EventEmitter();
  @Output() deactivate = new EventEmitter();
  @Output() bandwidth = new EventEmitter();

  bars: any;
  x: any;
  y: any;

  placementTypes = PlacementTypes;
  barOrientation = BarOrientation;
  styleTypes = StyleTypes;

  ngOnChanges(changes): void {
    if (changes.series || changes.xScale || changes.yScale || changes.type) {
      this.update();
    }
  }

  update(): void {
    if (!this.series || !this.series.length) return;  // Validación para evitar acceder a propiedades indefinidas.

    const width = this.xScale.bandwidth();
    this.bandwidth.emit(width);

    let d0 = 0;
    let total;

    if (this.type === 'normalized') {
      total = this.series.map(d => d.value).reduce((sum, d) => sum + d, 0);
    }

    this.bars = this.series.map((d, index) => {
      const value = d.value;
      const label = d.name;
      const formattedLabel = formatLabel(label);
      const roundEdges = this.type === 'standard';
      const bar: any = {
        value,
        label,
        roundEdges,
        data: d,
        width,
        formattedLabel,
        height: 0,
        x: 0,
        y: 0,
      };
      
      const calculateBarPosition = () => {
        if (this.type === 'standard') {
          bar.height = Math.abs(this.yScale(value) - this.yScale(0));
          bar.x = this.xScale(label);
          bar.y = value < 0 ? this.yScale(0) : this.yScale(value);
        } else if (this.type === 'stacked') {
          let offset1 = d0 + value;
          d0 += value;
          bar.height = this.yScale(d0 - value) - this.yScale(d0);
          bar.x = 0;
          bar.y = this.yScale(offset1);
          bar.offset0 = d0 - value;
          bar.offset1 = d0;
        } else if (this.type === 'normalized') {
          let offset1 = d0 + value;
          d0 += value;
          const offsetPercent0 = total > 0 ? (d0 - value) * 100 / total : 0;
          const offsetPercent1 = total > 0 ? offset1 * 100 / total : 0;
          bar.height = this.yScale(offsetPercent0) - this.yScale(offsetPercent1);
          bar.x = 0;
          bar.y = this.yScale(offsetPercent1);
          bar.offset0 = offsetPercent0;
          bar.offset1 = offsetPercent1;
          bar.value = `${(offsetPercent1 - offsetPercent0).toFixed(2)}%`;
        }
      };

      calculateBarPosition();

      bar.color = this.colors.scaleType === 'ordinal'
        ? this.colors.getColor(label)
        : this.type === 'standard'
          ? this.colors.getColor(value)
          : this.colors.getColor(bar.offset1);

      bar.gradientStops = this.colors.scaleType === 'ordinal'
        ? undefined
        : this.type === 'standard'
          ? this.colors.getLinearGradientStops(value)
          : this.colors.getLinearGradientStops(bar.offset1, bar.offset0);

      const tooltipLabel = this.seriesName ? `${this.seriesName} • ${formattedLabel}` : formattedLabel;
      const lineValue = this.seriesLine?.[0]?.series?.[index]?.value || 0;  // Validación adicional
      bar.tooltipText = `
        <span class="tooltip-label">${tooltipLabel}</span>
        <span class="tooltip-val"> Y1 - ${value.toLocaleString()} • Y2 - ${lineValue.toLocaleString()}%</span>
      `;

      return bar;
    });
  }

  isActive(entry): boolean {
    if (!this.activeEntries) return false;
    return this.activeEntries.some(d => entry.name === d.name && entry.series === d.series);
  }

  onClick(data): void {
    this.select.emit(data);
  }

  trackBy(index: number, bar: any): string {
    return bar.label;
  }
}
