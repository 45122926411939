import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseChartComponent, NgxChartsModule } from '@swimlane/ngx-charts';
import { LmSubscriptionReportingRoutingModule } from './lm-subscription-reportin.routeing';
import { MatCardModule } from '@angular/material/card';
import { LmSubscriptionReportingComponent } from './component/lm-subscription-reporting.component';
import { LmChartPieComponent } from './sections/lm-chart-pie/lm-chart-pie.component';
import { LmChartHorizontalBarComponent } from './sections/lm-chart-horizontal-bar/lm-chart-horizontal-bar.component';
import { LmChartVerticalBarComponent } from './sections/lm-chart-vertical-bar/lm-chart-vertical-bar.component';
import { LmChartSimpleVerticalBarComponent } from './sections/lm-chart-simple-vertical-bar/lm-chart-simple-vertical-bar.component';
import { LmChartComboComponent } from './sections/lm-chart-combo/lm-chart-combo.component';
import { ComboChartComponent, ComboSeriesVerticalComponent } from './sections/lm-chart-combo/combo-chart';
import { LmGroupTableComponent } from './sections/lm-group-table/lm-group-table.component';
import { LmGroupTableBasicComponent } from './sections/lm-group-table-basic/lm-group-table-basic.component';
import { LmGroupTableComparitionComponent } from './sections/lm-group-table-comparition/lm-group-table-comparition.component';
import { LmComparisonReportComponent } from './sections/lm-comparison-report/lm-comparison-report.component';
import { LmGlobalTableComponent } from './sections/lm-global-table/lm-global-table.component';



@NgModule({
  declarations: [
    LmSubscriptionReportingComponent,
    LmChartPieComponent,
    LmChartHorizontalBarComponent,
    LmChartSimpleVerticalBarComponent,
    LmChartComboComponent,
    LmChartVerticalBarComponent,
    ComboChartComponent,
    ComboSeriesVerticalComponent,
    LmGroupTableComponent,
    LmGroupTableBasicComponent,
    LmGroupTableComparitionComponent,
    LmComparisonReportComponent,
    LmGlobalTableComponent],
  imports: [
    CommonModule,
    NgxChartsModule,
    MatCardModule,
    LmSubscriptionReportingRoutingModule
  ],
  exports: [BaseChartComponent]
})
export class LmSubscriptionReportingModule { }
